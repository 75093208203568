import './AboutFestival.css'

function AboutFestival() {
    return(
        <section className="aboutFestivalHaifa__main-container" id='festival'>
            <h2 className="aboutFestivalHaifa__main-title">О ФЕСТИВАЛЕ</h2>

                <div className="aboutFestivalHaifa__title-container">
                <h1 className="aboutFestivalHaifa__sub-title aboutFestivalHaifa__sub-title_bigger">Книжный семейный фестиваль в Хайфе!</h1>
                </div>

                <ul className="aboutFestivalHaifa__text-wrapper">

                    <li className="aboutFestivalHaifa__text-container">
                        <p className="aboutFestivalHaifa__text">Приходите на мастер-классы по созданию театра теней или своего собственного ювелирного украшения, декорированию свечей и многого другого. Вас ждут лучшие книжные новинки от независимых издательств – летнее чтение для всех!</p>
                    </li>

                    <li className="aboutFestivalHaifa__text-container">
                        <p className="aboutFestivalHaifa__text">
                        Мы ждём детей от 5 лет с 14.00 до 19.00 на двухдневную кайтану! Сделаем невероятное шляпы из крафтовой бумаги, создадим карту Сказочного Мира, она станет основой для нашей собственной настольной игры. А в конце – сюрприз!
                        </p>
                    </li>

                    <li className="aboutFestivalHaifa__text-container">
                        <p className="aboutFestivalHaifa__text">
                        Фестиваль «В чемодане» проходит в культурном центре Бейт-а-Гефен, расположенном в Немецкой колонии Хайфы. Отличная транспортная доступность, вид на Бахайские сады, большая удобная территория!
                        </p>
                    </li>

                </ul>

                <p className="aboutFestivalHaifa__text-promotion">Остались вопросы? Позвоните нам! <br/>+ 972 53-470-1675</p>

        </section>
    )
}

export default AboutFestival;

{/*

<p>Добро пожаловать на главное русскоязычное событие лета в Израиле - Книжный семейный фестиваль в Хайфе! Этот уникальный фестиваль предлагает незабываемый опыт для всей семьи, насыщенный мастер-классами, спектаклями и лагерем для детей.</p>
            <p>Запланируйте незабываемое семейное приключение, посетив мастер-классы по созданию сказочных персонажей, изготовлению книжных закладок или даже мастерству письма. Развлекательные мероприятия на каждом шагу обеспечат вам возможность открыть для себя мир книг и чтения вместе с вашими близкими.</p>
            <p>Для самых маленьких участников фестиваля у нас имеется специально организованный лагерь "Кайтaна", где дети смогут погрузиться в увлекательные приключения, связанные с миром книг и фантазии под чутким руководством опытных вожатых.</p>
            <p>И все это происходит в одном из самых прекрасных городов Израиля - Хайфе, с его удивительной атмосферой и великолепными видами. Присоединитесь к нам на Книжном семейном фестивале и создайте незабываемые воспоминания вместе с вашей семьей!</p>

            <h3>🔥А для самых быстрых и решительных мы предлагаем промокод — до 12 июня на некоторые спектакли можно купить билеты со скидкой 20%! Не упустите!</h3>
*/}


//Развлекательные мероприятия на каждом шагу обеспечат вам возможность открыть для себя мир книг и чтения вместе с вашими близкими